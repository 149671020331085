.outerContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.container {
    display: flex;
    align-items: center;
    background-color: rgb(43, 89, 226);
    width: 100%;
    height: fit-content;
    margin: 2px;
    border-radius: 300px;
    padding: 5px;
}

.fill {
    border-radius: 300px;
    background-color: rgba(47, 187, 96, 0.434);
}