.App {
  display: flex;
  text-align: center;
  background-color: #f0f0f0;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

.bounce {
  animation: bounce 0.5s ease-in-out;
  transform-origin: left;
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: scale(0) translateY(0);
  }

  40% {
    opacity: 0.5;
    transform: scale(0.5);
  }

  60% {
    opacity: 1;
    transform: scale(1);
  }
}

.container {
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  justify-self: center;
  /* align-items: center; */
  width: 20vw;
}

@media (max-width: 600px) {
  .container {
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 150%;
  }
}

.nextContainer {
  margin-top: 10px;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
}

.next {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: rgba(19, 115, 188, 1);
}

.optionContainer {
  border-radius: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.option:hover {
  color: black;
  background-color: rgba(19, 115, 188, 0.5);
  border-width: 1px;
  border-color: rgba(218, 49, 49, 0.787);
  border-style: solid;
  /* width: 10px;
  height: 3px;
  background-color: #282c34;
  border-radius: 550vw; */
}

.option {
  width: 90%;
  border-radius: 300px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(4, 18, 30, 0.718);
  padding: 5px;
  color: black;
  cursor: pointer;
  margin: 10px;
  background-color: rgba(50, 143, 201, 0.226);
}

.disabled {
  width: 90%;
  border-radius: 5px;
  border-width: 1px;
  padding: 5px;
  /* color: rgb(255, 255, 255) !important; */
  margin: 10px;
  background-color: rgba(38, 38, 38, 0.226) !important;
}

.options {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
